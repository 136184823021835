import React, { useState, useEffect } from 'react';
import { IonIcon, IonInput, IonButton } from '@ionic/react';
import './index.css';
import { isDefined, isNativeAndroid } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import { NormalText, SmallText, StrongText } from '../common';
import check from '../../assets/images/Deli_Check.svg';
import wrong from '../../assets/images/Deli_Wrong.svg';

const { DatePicker } = Mobiscroll;

const ValidateInput = ({ __, label, type, value: propValue, name, validatedData, onIonChange, onButtonClicked, ...rest }) => {
  const [value, setValue] = useState(propValue || '');
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (name) {
      const valid = validatedData[name];
      setShow(valid);
    }
  }, [name, validatedData]);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const togglePass = () => {
    if (type === 'email') {
      const validEmail = isDefined(value) && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value);
      setShow(validEmail);
    } else {
      const validValue = isDefined(value) && value !== '';
      setShow(validValue);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    togglePass();
    if (onIonChange) {
      onIonChange(e);
    }
  };

  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  return (
    <div className="okx-validate-input-wrapper">
      <div className="okx-validate-input-holder inline-input inline-input--validate">
        {type === 'date' && (
          <div className="date-picker-wrapper inline-input inline-input--validate">
            <DatePicker
              className="data-picker-input"
              cssClass={isNativeAndroid() ? "mobiscroll-date-picker" : ""}
              display="bottom"
              setText="Done"
              max={yesterday}
              onSet={(e, a) => handleChange({ target: { value: a.element.value } })}
              {...rest}
              value={value}
            />
          </div>
        )}
        {type === 'text' && <IonInput {...rest} value={value} onIonInput={handleChange}></IonInput>}
        {type === 'email' && <IonInput readonly {...rest} value={value} onIonInput={handleChange}></IonInput>}
        {type === 'tel' && <IonInput {...rest} value={value} onIonInput={handleChange}></IonInput>}
        {type === 'email_verified' && (
          <>
            {show ? (
              <NormalText>{__('Email Address Verified')}</NormalText>
            ) : (
              <StrongText className='bold underlined secondary-color pointer' onClick={onButtonClicked}>
                {__('Resend address validation email')}
              </StrongText>
            )}
          </>
        )}
      </div>
      <IonButton fill="clear" slot="icon-only" onTouchStart={togglePass} onTouchEnd={togglePass}>
        <IonIcon slot="icon-only" icon={show ? check : wrong} />
      </IonButton>
    </div>
  );
};

export default withTranslation(ValidateInput);
